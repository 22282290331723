import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import categoryReducer from "../Reducers/categorySlice";
import tourReducer from "../Reducers/tourSlice";
import vehicleReducer from "../Reducers/vehicleSlice";
import claimFormReducer from "../Reducers/claimFormSlice";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  // Add other reducers to whitelist if needed
};

// Combine all reducers
const rootReducer = combineReducers({
  category: categoryReducer,
  tour: tourReducer,
  vehicle: vehicleReducer,
  claimForm: claimFormReducer,
  // Add more reducers as needed
});

// Wrap the combined reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer and customized middleware
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Export the persistor instance
export const persistor = persistStore(store);
